require('./bootstrap');

// Remove these lines since jQuery is now loaded in the layout
// import $ from 'jquery';
// window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/tooltip.js';

// floating label
import 'floating-form-labels/dist/floatingFormLabels.min.js';


$(document).ready(function () {
    $.fn.scrollBottom = function () {
        return $(document).height() - this.scrollTop() - this.height();
    };

    // Sticky menu
    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('#logo-header').addClass('sticked');
            $('#navbar').addClass('sticked');
            $('#header-monespace').addClass('sticked');
            $('.switch--vertical').addClass('sticked');
        } else {
            $('#logo-header').removeClass('sticked');
            $('#navbar').removeClass('sticked');
            $('#header-monespace').removeClass('sticked');
            $('.switch--vertical').removeClass('sticked');
        }
    });


    // sharing more popover
    $('a.sharing-more').on('click', function (e) { e.preventDefault(); return true; });
    //        $('a.cpy').on('click', function(e) {e.preventDefault(); return true;});

    // js // toggle links
    $('a.toggle').on('click', function (e) { e.preventDefault(); return true; });

    let sharingMoreContent = $("#sharing-more-content").html();
    $(".sharing-more").popover({
        html: true,
        container: 'body',
        placement: 'right',
        sanitize: false,
        content: function () {
            return sharingMoreContent;
        },
        title: "",
    });

    $('.sharing-more').on('hidden.bs.popover', function () {
        $('.sharing-more').popover('dispose');
        $(".sharing-more").popover({
            html: true,
            container: 'body',
            placement: 'right',
            sanitize: false,
            content: function () {
                return sharingMoreContent;
            },
            title: "",
        });
    })

    $(document).on('click', '#close-share', function (e) {
        $('.sharing-more').popover('hide');
    });

    $('.sharing-more').on('shown.bs.popover', function () {
        $("#sharing-more-content").empty();
        $('a.cpy').on('click', function (e) {
            e.preventDefault();
            $('.shareable').focus();
            $('.shareable').select();
            document.execCommand("copy");
            $(".sharing-message").text("lien copié");
        });
    });
    /*
        setTimeout(function () {
            var $Input = $('input:-webkit-autofill');
            $Input.next("label").addClass('active');
        }, 200);
    */



    // Bootstrap carousel for mobile
    $('#carouselExampleIndicators1').carousel({
        interval: 3000,
        ride: 'carousel',
        wrap: true,
        pause: false
    });

    // Slick carousels
    $('.carousel-box-1, .carousel-box-3').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: true,
        customPaging: function (slider, i) {
            return '<button type="button"></button>';
        }
    });

    // Initialize carousel-box-2 with autoplay: false first
    $('.carousel-box-2').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,  // Start with autoplay off
        autoplaySpeed: 4000,
        adaptiveHeight: true,
        customPaging: function (slider, i) {
            return '<button type="button"></button>';
        }
    });

    // Start carousel-box-2 after 1 second delay
    setTimeout(function () {
        $('.carousel-box-2').slick('slickPlay');
    }, 1000);

    // prevent normal submit on searh form (ajax only)
    $(".no-default").submit(function (e) {
        e.preventDefault();
    });

    $('#number-svg-1').click(function () {
        $('.mode-emploi').hide();
        $('#mode-emploi-1').fadeIn();
        $('.number-svg').removeClass('active');
        $('#number-svg-1').addClass('active');
    });

    $('#number-svg-2').click(function () {
        $('.mode-emploi').hide();
        $('#mode-emploi-2').fadeIn();
        $('.number-svg').removeClass('active');
        $('#number-svg-2').addClass('active');
    });

    $('#number-svg-3').click(function () {
        $('.mode-emploi').hide();
        $('#mode-emploi-3').fadeIn();
        $('.number-svg').removeClass('active');
        $('#number-svg-3').addClass('active');
    });

    $('#garantie-1').click(function () {
        $('.texte-garantie').hide();
        $('#text-garantie-1').fadeIn();
        $('.bouton-garantie').removeClass('active');
        $('#garantie-1').addClass('active');
    });

    $('#garantie-2').click(function () {
        $('.texte-garantie').hide();
        $('#text-garantie-2').fadeIn();
        $('.bouton-garantie').removeClass('active');
        $('#garantie-2').addClass('active');
    });

    $('#garantie-3').click(function () {
        $('.texte-garantie').hide();
        $('#text-garantie-3').fadeIn();
        $('.bouton-garantie').removeClass('active');
        $('#garantie-3').addClass('active');
    });

    $('#garantie-1-1').click(function () {
        $('.texte-garantie').hide();
        $('#text-garantie-1-1').fadeIn();
        $('.bouton-garantie').removeClass('active');
        $('#garantie-1-1').addClass('active');
    });

    $('#garantie-2-1').click(function () {
        $('.texte-garantie').hide();
        $('#text-garantie-2-1').fadeIn();
        $('.bouton-garantie').removeClass('active');
        $('#garantie-2-1').addClass('active');
    });

    $('#garantie-3-1').click(function () {
        $('.texte-garantie').hide();
        $('#text-garantie-3-1').fadeIn();
        $('.bouton-garantie').removeClass('active');
        $('#garantie-3-1').addClass('active');
    });
});
